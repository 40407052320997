<template>
  <div class="container-fluid">
    <div class="row align-items-stretch">
      <div class="col-lg-6 col-sm-12 mb-3">
        <div class="card hreg--card-info d--bg-gray-50 h-100">
          <card-header
            class="marginHeader"
            :title="$t('identityForm.personalCard.persCardHeader')"
          />
          <small class="d--mvb-4">
            <a target="_blank" rel="noreferrer noopener nofollow" class="linkEid text-primary" :href="$t('identityForm.whatIsEidLink')">{{ $t('identityForm.whatIsEid')  }}</a>
          </small>
          <ul id="identityList" class="d--no-indent">
            <li>{{ $t("identityForm.personalCard.compareToId") }}</li>
            <li>{{ $t("identityForm.personalCard.uniquePerson") }}</li>
            <li>{{ $t("identityForm.personalCard.appAccess") }}</li>
            <li>{{ $t("identityForm.personalCard.hinServices") }}</li>
            <li>{{ $t("identityForm.personalCard.emailExample") }}</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 mb-3">
        <div class="card hreg--card-info d--bg-gray-50 h-100">
          <card-header
            class="marginHeader"
            :title="$t('identityForm.teamCard.teamCardHeader')"
          />
          <small class="d--mvb-4">
            <a target="_blank" rel="noreferrer noopener nofollow" class="linkEid text-primary" :href="$t('identityForm.whatIsEidLink')">{{ $t('identityForm.whatIsEid')  }}</a>
          </small>
          <ul id="identityList" class="d--no-indent">
            <li>{{ $t("identityForm.teamCard.multiplePersons") }}</li>
            <li>{{ $t("identityForm.teamCard.limitedAccessData") }}</li>
            <li>{{ $t("identityForm.teamCard.limitedAccessServices") }}</li>
            <li>{{ $t("identityForm.teamCard.emailExample") }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-sm-12 mb-3">
        <personal-form-list
          :ref="(el) => formListRefs[0] = el"
          :forms="identityStore.personalForms"
          :formString="$t('identityForm.persFormName')"
          :trashCanText="$t('identityForm.trashCanText')"
          formName="personalForms"
        />
        <personal-form-list
          :ref="(el) => formListRefs[1] = el"
          :forms="identityStore.mpaForms"
          :formString="$t('identityForm.mpaFormString')"
          :trashCanText="$t('identityForm.trashCanText')"
          formName="mpaForms"
        />
        <add-form-button
          tabindex="0"
          :linkText="$t('identityForm.addButtons.persLinkText')"
          data-test="add-pers-form-button"
          :formName="$t('identityForm.persFormName')"
          :addFormId="1"
          @handleClick="
            (formName, addFormId) => addNewForm(formName, addFormId)
          "
          v-if="
            mpaForms.length + personalForms.length + teamForms.length < maxForms
          "
        />
        <add-form-button
          tabindex="0"
          :linkText="$t('identityForm.addButtons.mpaLinkText')"
          data-test="add-mpa-form-button"
          :formName="$t('identityForm.mpaFormString')"
          :addFormId="2"
          @handleClick="
            (formName, addFormId) => addNewForm(formName, addFormId)
          "
          v-if="
            mpaForms.length + personalForms.length + teamForms.length < maxForms
          "
        />
      </div>
      <div class="col-lg-6 col-sm-12 mb-3">
        <team-form-list
          :ref="(el) => formListRefs[2] = el"
          :teamFormString="$t('identityForm.teamCard.teamCardHeader')"
          :trashCanText="$t('identityForm.trashCanText')"
          formName="teamForms"
        />
        <add-form-button
          tabindex="0"
          :linkText="$t('identityForm.addButtons.teamLinkText')"
          :formName="$t('identityForm.teamCard.teamCardHeader')"
          data-test="add-team-form-button"
          :addFormId="3"
          @handleClick="
            (formName, addFormId) => addNewForm(formName, addFormId)
          "
          v-if="
            mpaForms.length + personalForms.length + teamForms.length < maxForms
          "
        />
      </div>
    </div>
    <hr class="d--divider-h" />

    <button-box
      :routeBack="routeHome"
      :routeForward="routeEmail"
      :forwardBtnText="$t('form.button.forward')"
      :disableBtn="!identityStore.hasValues || identityStore.hasErrors"
    />
  </div>
</template>

<script setup>
import CardHeader from "@/components/CardHeader.vue";
import AddFormButton from "@/components/AddFormButton.vue";
import ButtonBox from "@/components/ButtonBox.vue";
import TeamFormList from "../components/TeamFormList.vue";
import PersonalFormList from "../components/PersonalFormList.vue";
import { onMounted, nextTick, ref, watch } from "vue";
import { useIdentityStore } from "@/store/IdentityStore";
import { useAppStore } from "../store/AppStore";
import { useMembersStore } from "../store/MembersStore";
import { useOfferStore } from "@/store/OfferStore";

const appStore = useAppStore();
const identityStore = useIdentityStore();
const membersStore = useMembersStore();
const offerStore = useOfferStore();

appStore.activeStep = appStore.screenNumbers.identity;

// Variables + Values
const mpaForms = ref(identityStore.mpaForms);
const personalForms = ref(identityStore.personalForms);
const teamForms = ref(identityStore.teamForms);
const maxForms = ref(identityStore.maxForms);

// Text + Strings
const routeHome = appStore.routeHome;
const routeEmail = appStore.routeEmail;

watch(
  [personalForms, mpaForms, teamForms],
  () => {
    identityStore.setErrors();
    identityStore.setEmpty();
  },
  { deep: true }
);

const formListRefs = ref([]);

const addNewForm = (formName, addFormId) => {
  identityStore.addNewForm(formName, addFormId);
  let index;
  switch (addFormId) {
    case 3:
      index = identityStore.teamForms.length -1;
      break;
    case 2:
      index = identityStore.mpaForms.length -1;
      break;
    case 1:
      index = identityStore.personalForms.length -1;
      break;
  }
  nextTick(() => {
    formListRefs.value[addFormId-1].focusCard(index);
  });
};

onMounted(() => {
  // Scroll to start of page
  window.scrollTo(0, 0);

  try {
    let associationSelection = ref(membersStore.associationSelection);
    const defaultAssociation = ref(membersStore.defaultAssociation);
    // Customers with association typically have at least one personal
    // and one team eID, so show them each of these eIDs already "open".
    // If they see one personal eID card and one team card empty when they
    // enter the screen, the hope is they can easier fill it out.
    if (
      associationSelection !== defaultAssociation &&
      associationSelection?.value !== undefined &&
      associationSelection?.value !== ""
    ) {
      // due to changes in addNewForm(), the input here can be an empty string
      // because the headers will be passed as props to the components
      // and not inside the function anymore BUT the parameter is still needed
      if (identityStore.personalForms.length === 0) {
        identityStore.addNewForm("", 1);
      }
      if (identityStore.teamForms.length === 0 && !offerStore.retiredCheckbox) {
        identityStore.addNewForm("", 3);
      }
    }
  } catch (error) {
    console.log("error: ", error);
  }

  // Select the first input element on the page
  nextTick(() => {
    const firstInput = document.querySelector('input');
    if (firstInput) {
      firstInput.focus();
    }
  });
});
</script>

<style scoped>
#identityList {
  margin-left: 15px;
}
.linkEid{
  padding-left: 20px;
}
.marginHeader{
  padding-bottom: 0px;
}
</style>
