<template>
  <div>
    <div class="card hreg--card-info d--bg-gray-50 d--mvb-4" @click="focusFirstInput">
      <div class="card d--card-borderless">
        <div class="card-header d--bg-gray-50">
          <div class="row align-items-center">
            <div class="col">
              <p class="m-0">
                <b>{{ formString }}</b>
              </p>
            </div>
            <div class="col-auto">
              <remove-form-button
                :trashCanText="trashCanText"
                :formName="formName"
                :index="index"
              />
            </div>
          </div>
        </div>

        <div id="cardBody" class="card-body">
          <div class="required form-group">
              <InputText
                ref="inputRef"
                :label="$t('appComponents.inputTeamName')"
                :id="'teamName'"
                :name="'teamName'+index"
                :required="`required`"
                v-model="teamName.value"
                :error="identityStore.teamForms[index].teamName.error"
                @validate="
                  (event) =>
                    identityStore.validateTeamName(
                      'teamForms',
                      index,
                      event.target.value,
                      'teamName'
                    )
                "
                @change="$emit('updateValue', $event)"
              />
              <small id="smallText" class="form-text text-muted d--pvb-4">
                {{ $t("appComponents.smallText") }}</small
              >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import RemoveFormButton from "./RemoveFormButton.vue";
import { useIdentityStore } from "@/store/IdentityStore";
import InputText from "./form/InputText.vue";

const identityStore = useIdentityStore();

const props = defineProps({
  formString: String,
  trashCanText: String,
  teamName: Object,
  index: Number,
  validators: Object,
  formName: String,
});

const inputRef = ref();
function focusFirstInput () {
  if (inputRef.value) {
    inputRef.value.focusInput();
  }
};

defineExpose({
  focusFirstInput
});

const teamName = props.teamName;
</script>

<style scoped>
#smallText {
  margin-top: -15px;
}
</style>
