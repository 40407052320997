<script setup>
import ErrorSmall    from "@/components/form/ErrorSmall.vue";
import LabelForInput from "@/components/form/LabelForInput.vue";
defineProps({
  hint: "",
  label: "",
  name: "",
  required: "",
  error: "",
  rows: 3,
  maxLength: 500,
});

const value = defineModel();
const emits = defineEmits(['validate']);
</script>

<template>
  <div :class="['form-group', required && 'required']">
    <LabelForInput :name :hint :label />
    <textarea
      :class="['form-control','max-width', error && 'is-invalid']"
      :id="name"
      :name
      :rows
      :maxlength="maxLength"
      :required
      v-model="value"
  >
    {{ value }}
  </textarea>

  <ErrorSmall :name :error />
</div>
</template>
<style scoped>
.max-width {
  max-width: 500px;
}
</style>
