import { createRouter, createWebHistory } from 'vue-router';

import MembersForm from '@/views/MembersForm.vue';
import IdentityForm from '@/views/IdentityForm.vue';
import EmailForm from '@/views/EmailForm.vue';
import OfferForm from '@/views/OfferForm.vue';
import ContractDetail from '@/views/ContractDetail.vue';
import FurtherInformation from '@/views/FurtherInformation.vue';
import VideoIdentificationWelcome from '@/views/VideoIdentificationWelcome.vue';
import VideoIdentification from '@/views/VideoIdentification.vue';
import IdentSuccess from '@/views/IdentSuccess.vue';
import IdentFailure from '@/views/IdentFailure.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: MembersForm
        },
        {
            path: '/membership-order',
            name: 'identity-form',
            component: IdentityForm
        },
        {
            path: '/membership-email',
            name: 'email-form',
            component: EmailForm
        },
        {
            path: '/membership-offer',
            name: 'offer-form',
            component: OfferForm
        },
        {
            path: '/membership-contract-detail',
            name: 'contract-detail-form',
            component: ContractDetail
        },
        {
            path: '/further-information',
            name: 'further-information-form',
            component: FurtherInformation,
        },
        {
            path: '/video-identification-welcome',
            name: 'video-identification-welcome-form',
            component: VideoIdentificationWelcome,
        },
        {
            path: '/video-identification',
            name: 'video-identification-form',
            component: VideoIdentification,
        },
        {
            path: '/ident-success',
            name: 'ident-success',
            component: IdentSuccess,
        },
        {
            path: '/ident-failure',
            name: 'ident-failure',
            component: IdentFailure,
        },
    ]
});

export default router;
