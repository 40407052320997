import { createI18n } from 'vue-i18n';

import de from './locales/de.js';
import fr from './locales/fr.js';
import it from './locales/it.js';

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  globalInjection: true,
  messages: {
    de,
    fr,
    it,
  },
});

export default i18n;

export const i18nGlobal = i18n.global;
