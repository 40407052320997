<template>
  <input-form-group
    v-for="(form, index) in filteredForms"
    :form="form"
    :key="index"
    :formName
    :index
    :placeholder="placeholder"
    :label="
      formName === 'teamForms'
        ? form.teamName.value
        : form.firstName.value + ' ' + form.lastName.value
    "
    :defaultValue="
      formName === 'teamForms'
        ? form.teamName.value
        : form.firstName.value + '.' + form.lastName.value
    "
    :error="forms[index].hinEmail.error"
    @validate="
      identityStore.validateEmail(formName, index, forms[index].hinEmail.value, appStore.activeStep)
    "
  />
</template>
<script setup>
import InputFormGroup from "./InputFormGroup.vue";
import { useIdentityStore } from "../store/IdentityStore";
import { computed } from "vue";
import { useAppStore } from "@/store/AppStore";

const appStore = useAppStore();
const identityStore = useIdentityStore();
const props = defineProps({
  forms: Array,
  placeholder: String,
  formName: String,
});

const filteredForms = computed(() => {
  if (props.formName === "personalForms" || props.formName === "mpaForms") {
    return props.forms.filter(
      (form) =>
        form.firstName.value !== "" &&
        form.firstName.value !== null &&
        form.firstName.value !== undefined &&
        form.lastName.value !== "" &&
        form.lastName.value !== null &&
        form.lastName.value !== undefined
    );
  }
  return props.forms.filter(
    (form) =>
      form.teamName.value !== "" &&
      form.teamName.value !== null &&
      form.teamName.value !== undefined
  );
});
</script>
