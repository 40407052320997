<template>
  <nav class="navbar navbar-light navbar-expand d--bg-white d-print-none">
    <div class="container-fluid">
      <a class="navbar-brand" href="/#">
        <img class="col" src="/assets/images/hin-logo.svg" alt="HIN-Logo" />
      </a>

      <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="/#">
              {{ $t("appComponents.appHeaderTitle") }}
            </a>
          </li>
        </ul>
      </div> -->
    </div>
    <!-- I am not happy that we have to set 2 classes
         and an `aria-expanded` to toggle the dropdown,
         but that is how I found it in the original.
         If anybody has time to simplify this, please make it so.
         Probably simple with a `v-if`
    -->
    <div class="hreg--language d--mvb-2">
      <span :class="['dropdown', 'd--link-dropdown', langDropDownOpen && 'show']" ref="dropdown">
        <a
          class="nav-link dropdown-toggle col-4"
          href="#"
          id="navbarDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          :aria-expanded="langDropDownOpen ? true : false"
          @click="toggleLangDropDownOpen"
        >
          {{ appStore.currentLanguage }}
        </a>
        <div
          :class="['dropdown-menu', 'dropdown-menu-right', langDropDownOpen && 'show']"
          aria-labelledby="navbarDropdown"
        >
          <a v-for="language in languages"
            :class="['dropdown-item', language === appStore.currentLanguage && 'active']"
            :key="language"
            href="#"
            @click="changeLocale(language)"
          >
            {{ language }}
          </a>
        </div>
      </span>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import i18n from "@/i18n.js";
import { useAppStore } from "@/store/AppStore";
const appStore = useAppStore();

const languages = {
  de: "DE",
  fr: "FR",
  it: "IT",
};

// If user refresh app, use current locale.
// AppStore is persisted in sessionStorage, so the
// locale is there. We have to tell i18n, or default
// locale is used.
// To be extra sure and be safe against timing issues,
// also install a watcher.
onMounted(() => {
  // Use browser language as default.
  // If browser language is neither of our supported languages,
  // default to "DE" by doing nothing. (AppStore has "DE" set as default, already.)
  const lang = navigator.language.substring(0, 2).toUpperCase();
  if (languages[lang]) {
    appStore.currentLanguage = lang;
  }
  changeLocale(appStore.currentLanguage);
});
watch(() => appStore.currentLanguage, (newLocale, oldLocale) => (newLocale !== oldLocale)
  && changeLocale(newLocale));

const langDropDownOpen = ref(false);
const dropdown = ref(null);

function changeLocale(locale) {
  i18n.global.locale = locale.toLowerCase();
  langDropDownOpen.value = false;
  appStore.currentLanguage = locale;
}

function toggleLangDropDownOpen() {
  langDropDownOpen.value = !langDropDownOpen.value;
}

function handleClickOutside(event) {
  if (dropdown.value && !dropdown.value.contains(event.target)) {
    langDropDownOpen.value = false;
  }
}
document.addEventListener('click', handleClickOutside);
</script>
