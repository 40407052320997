<template>
  <div>
    <div class="container-fluid">

        <!--
          If you change anything here, make sure backend templates for
          emails after step 5 get updated and tested.
          (Customer confirmation, CCC)
        -->

        <span v-if="offerStore.isLoading" class="d--loading "></span>
        <card-header  v-else class="headerAlign" title="">
          <caption class="text-dark">
            <b>
              {{ offerStore.pageHeader ? $t(offerStore.pageHeader) : "" }}
              {{ offerStore.associationName }}
            </b>
          </caption>
          <table
            class="table table-sm"
            aria-describedby="This table lists the count and prices associated with the items"
          >
            <thead class="p-3 d--bg-gray-100">
              <tr class="text-right">
                <th scope="col"></th>
                <th scope="col">
                  {{ $t("offerForm.tableHeaders.countText") }}
                </th>
                <th scope="col" colspan="2">
                  {{ $t("offerForm.tableHeaders.singlePriceText") }}
                </th>
                <th scope="col" colspan="2">
                  {{ $t("offerForm.tableHeaders.totalPriceText") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-right">
                <td class="text-left">
                  <em>
                    {{ $t("offerForm.tableContentAdmission.admissionCostText") }}
                  </em>
                </td>
              </tr>
              <!-- admission -->
              <!-- ========= -->
              <tr class="text-right">
                <td class="text-left">
                  {{ $t("offerForm.tableContentAdmission.admissionFeeText") }}
                </td>
                <td>{{ offer.admission?.ids.number }}</td>
                <td>{{ $t("offerForm.currency") }}</td>
                <td>{{ formatAmount(offer.admission?.ids.amount) }}</td>
                <td>{{ $t("offerForm.currency") }}</td>
                <td>{{ formatAmount(offer.admission?.ids.sum) }}</td>
              </tr>

              <!-- sponsor -->
              <tr v-if="offer.admission?.ids.sponsor">
                <td>
                  {{ $t("offerForm.sponsorText") }} {{ offer.admission.ids.sponsor.name }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ $t("offerForm.currency") }}</td>
                <td class="text-right">
                  {{ formatAmount(offer.admission?.ids.sponsor.sum) }}
                </td>
              </tr>

              <!-- discount -->
              <tr v-if="offer.admission?.discountAmount.sum">
                <td>
                  {{ $t("offerForm.tableContentAdmission.admissionDiscountText",
                    {name: offerStore.associationName}) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ $t("offerForm.currency") }}</td>
                <td class="text-right">
                  {{ formatAmount(offer.admission?.discountAmount.sum) }}
                </td>
              </tr>

              <!-- total -->
              <tr>
                <td>
                  <b>
                    {{ $t("offerForm.tableContentAdmission.admissionCostTotalText") }}
                  </b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ $t("offerForm.currency") }}</td>
                <td class="text-right">
                  <b>{{ formatAmount(offer.admission?.total.sum) }}</b>
                </td>
              </tr>
              <tr class="d--divider-h">
                <td>
                  <em>{{
                    $t("offerForm.tableContentAnnual.annualCostText")
                  }}</em>
                </td>
              </tr>

              <!-- eIDs -->
              <!-- ==== -->
              <template v-for="type in ['personalIds', 'mpaIds', 'teamIds']">
                <template  v-if="offer.annual?.[type].number > 0">
                  <tr class="text-right">
                    <td class="text-left">
                      {{ $t("offerForm.tableContentAnnual."+type+"Text") }}
                    </td>
                    <td>{{ offer.annual?.[type].number }}</td>
                    <td>{{ $t("offerForm.currency") }}</td>
                    <td>{{ formatAmount(offer.annual?.[type].amount) }}</td>
                    <td>{{ $t("offerForm.currency") }}</td>
                    <td>{{ formatAmount(offer.annual?.[type].sum) }}</td>
                  </tr>

                  <!-- sponsor -->
                  <tr v-if="offer.annual?.[type].sponsor">
                    <td>
                      {{ $t("offerForm.sponsorText") }} {{ offer.annual[type].sponsor.name }}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right">{{ $t("offerForm.currency") }}</td>
                    <td class="text-right">
                      {{ formatAmount(offer.annual[type].sponsor.sum) }}
                    </td>
                  </tr>



                  <!-- discount -->
                  <tr class="text-right" v-if="offer.annual?.[type].discount?.sum">
                    <td class="text-left">
                      {{ $t("offerForm.tableContentAnnual.personalIdsDiscount") }}
                      ({{ offerStore.associationName }})
                      {{ offer.annual?.[type].discount?.percent }} %
                    </td>
                    <td>{{ offer.annual?.[type].discount?.number }}</td>
                    <td>{{ $t("offerForm.currency") }}</td>
                    <td>{{ formatAmount(offer.annual?.[type].discount?.amount) }}</td>
                    <td>{{ $t("offerForm.currency") }}</td>
                    <td>{{ formatAmount(offer.annual?.[type].discount?.sum) }}</td>
                  </tr>
                </template>
              </template>

              <!-- discount amount -->
              <tr
                class="text-right"
                v-if="offer.annual?.discountAmount?.sum"
              >
                <td class="text-left">
                    {{ $t("offerForm.tableContentAdmission.admissionDiscountText",
                      {name: offerStore.associationName }) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ $t("offerForm.currency") }}</td>
                <td>{{ formatAmount(offer.annual?.discountAmount.sum) }}</td>
              </tr>

              <!-- annual total -->
              <tr>
                <td>
                  <b>
                    {{ $t("offerForm.tableContentAnnual.annualCostTotalText") }}
                  </b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">
                  <b>
                    {{ $t("offerForm.currency") }}
                  </b>
                </td>
                <td class="text-right">
                  <b >
                    {{ formatAmount(offer.annual?.total.sum) }}
                  </b>
                </td>
              </tr>

              <!-- overal total -->
              <tr id="tableRowCostum" class="d--divider-h text-right">
                <td class="text-left">{{ $t("offerForm.totalAmountText") }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">
                  <b>
                    {{ $t("offerForm.currency") }}
                  </b>
                </td>
                <td class="text-right">
                  <b>
                    {{ formatAmount(offer.total?.sum) }}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </card-header>
      <div class="mt-3"></div>
    </div>
    <div class="row">
      <button-box
        :routeBack="routeEmail"
        :routeForward="routeContract"
        :forwardBtnText="$t('offerForm.btnForward')"
      />
    </div>
    <Modal
      v-show="offerStore.errorModalOpen"
      :header="$t('offerForm.errorModal.header')"
      :bodyComponent="ErrorModalBody"
      :bodyComponentProps="{errors: offerStore.errors}"
      @close="offerStore.errorModalOpen=false"
    />

  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useOfferStore }    from "@/store/OfferStore";
import { useAppStore }      from "@/store/AppStore";
import { useMembersStore }  from "@/store/MembersStore";

import ButtonBox              from "@/components/ButtonBox.vue";
import CardHeader             from "@/components/CardHeader.vue";
//import InputCheckbox          from "@/components/form/InputCheckbox.vue";
import Modal                  from "@/components/Modal.vue";
import ErrorModalBody         from "@/components/offer/ErrorModalBody.vue";

// Stores
const offerStore   = useOfferStore();
const offer        = computed(() => offerStore.offer);
const appStore     = useAppStore();
const membersStore = useMembersStore();

// Variables + Values

appStore.activeStep = appStore.screenNumbers.offer;
// Text + Strings
const routeEmail = appStore.routeEmail;
const routeContract = appStore.routeContract;
const userClosedModal = ref(false);

// function handleRetiredChange(event) {
  // update the store explicitly. It seems that the code here is
  // faster than the store update through v-model.
//  offerStore.retiredCheckbox = event.target.value;
//  offerStore.getOffer();
//}

function formatAmount(amount = 0) {
    // Convert number to string with fixed decimal places. 1234 -> 1234.00
    let formattedAmount = parseFloat(amount).toFixed(2);

    // Separate thousands with apostrophe
    // This replaces a "non border (inside string or number),
    // then 3 digits, a non-digit and 2 digits (at the end of a string)
    // with an apostrophe and itself (by capturing wit "()").
    // It will replace 1234.00 with 1'234.00, but it will not replace 123.00 or -123.00
    formattedAmount = formattedAmount.replace(/\B(\d{3}\D\d{2})$/, "'$1");
    return formattedAmount;
}

function showRetirementCb() {
  return membersStore.associationSelection === "FMH";
}

onBeforeMount(() => {
  offerStore.getOffer();
});

</script>

<style scoped>
#cardOffer {
  height: auto;
}
.table th,
.table td {
  border-top: none;
  font-size: 14px;
}

.table td:nth-child(4), td:nth-child(6) {
  width: 15px;
  white-space: nowrap;
}
.table td:nth-child(3), td:nth-child(5) {
  padding-left: 50px;
}

.row-margin {
  margin-top: -10px;
}

#tableRowCostum {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #dde1e6;
  font-weight: bold;
}
.headerAlign{
  max-width: 1000px;
}
.d--loading {
  font-size: 2em;
  width: 50px;
  height: 50px;
}
</style>
