<script setup>
import { ref } from 'vue';
import ErrorSmall    from "@/components/form/ErrorSmall.vue";
import LabelForInput from "@/components/form/LabelForInput.vue";
import DeleteInputIcon from "@/components/form/DeleteInputIcon.vue";
const props = defineProps({
  hint: "",
  label: "",
  name: "",
  required: "",
  error: "",
  suggestions: Array,
});

const emit = defineEmits(['validate', 'done']);
const value = defineModel();

const isOpen = ref(false);
function onInput() {
  isOpen.value = true;
}

function setSelected(result) {
  value.value = result.name;
  isOpen.value = false;
}

</script>

<template>
  <div :class="['form-group', required && 'required']">
    <LabelForInput :name :hint :label />
    <div>
      <div class="input-container">
        <input
          type="text"
          :name
          :id="name"
          :required
          :class="['form-control', error && 'is-invalid']"
          v-model="value"
          @blur="$emit('validate', $event)"
          @input="onInput"
        />
        <DeleteInputIcon v-if="error" @clearInput="value = ''" />
      </div>
    </div>
    <ul
      v-show="isOpen"
      class="company-search-suggestions"
    >
      <li
        class="company-search-item"
        v-for="(suggestion, index) in suggestions"
        :key="index"
        @click="setSelected(suggestion); $emit('done', suggestion.uid)"
      >
        {{ suggestion.name }}
      </li>
    </ul>
    <ErrorSmall :name :error :label/>
  </div>
</template>

<style>
  .company-search-suggestions {
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    background-color: white;
    max-height: 200px;
    overflow-y: auto;
  }

  .company-search-item {
    cursor: pointer;
    padding: 12px 16px;
  }
</style>
