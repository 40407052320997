<script setup>
import ErrorSmall from "@/components/form/ErrorSmall.vue";
defineProps({
  label: "",
  name: "",
  required: "",
  error: "",
});

const checked = defineModel();
const emits = defineEmits(['validate']);

</script>

<template>
  <div class="form-group custom-control custom-checkbox">
    <!-- note: "id" is needed for click magic
      $emit with !checked is done because if I "checked = !checked"
      first, somehow the validator still got the old value.
      $event cannot be used for checkbox, so construct
      our own little object with target, name and value.
    -->
    <input
      type="checkbox"
      class="custom-control-input"
      :id="name"
      :name
      :required
      :checked
      @change="$emit('validate', {target: {name: name, value: !checked}}); checked = !checked"
    />
    <label class="custom-control-label" :for="name">
      <slot name="label">{{ label }}</slot>
    </label>
    <ErrorSmall :name :error/>
  </div>
</template>
