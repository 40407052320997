import { defineStore } from "pinia";
import { useAgwManagerService } from "@/services/AgwManagerService";

const agwManagerService = useAgwManagerService();

export const useInputOptionStore = defineStore('inputOptionStore', {
  state: () => {
    return {
      inputOptions: {
        associations: {},
        cardReaderOrder: {},
        countries: {},
        languages: {},
        orgType: {},
        professions: {},
        profTitles: {},
        salTitles: {},
        salutations: {},
        telCountries: {},
      },
      modalErrorOpen: false,
    };
  },
  actions: {
    async fillInputOptions() {
      for (const topic in this.inputOptions) {
        if (Object.keys(this.inputOptions[topic] || {}).length > 0) {
          continue;
        }
        try {
          const data = await agwManagerService.fetchJson(
            "/productfinder/input-options?topic="+topic,
            {
              method: "GET",
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          // telephone country preselects come in as
          // { de: [{code: "+41", de: "Schweiz +41", key: "CH"}..], fr: [..] ..}
          // but they want to display "CH +41"
          if (topic === 'telCountries') {
            // Convert into object with languages as keys.
            this.inputOptions[topic] = Object.fromEntries(
              // Iterate all languages and return array of arrays.
              Object.entries(data.result).map(([key, values]) => {
                return [
                  key,
                  values.map(({ key, de, code }) => ({
                    key,
                    de: `${key} ${code}`,
                    fr: `${key} ${code}`,
                    it: `${key} ${code}`
                  }))
                ];
              })
            );
          }
          else {
            this.inputOptions[topic] = data.result;
          }

          // Create lookup object, so we can easily say "inputStore[topic].byKey[key]
          // and get all languages.
          // Example: inputOptions.saluatation.byKey = {MALE: {key: "MALE", de: "Herr", ...}}
          this.inputOptions[topic].byKey = {};
          // It does not matter which language we iterate, they all must contain same data.
          data.result.de.forEach(item => this.inputOptions[topic].byKey[item.key] = item);

          // If we have to dump what we get from SDS.
          // I saw various possible data issues. (mb, 2024-08-27), noted in PROFIN-119.
          // for (const item of this.associations.de) {
          //   console.log("key", item.key, "remark", item.remark, "\nURL DE", item.registrationLink.de, "URL FR", item.registrationLink.fr);
          // }
        }
        catch (error) {
          console.log(`Filling InputOptions for '${topic}' failed:`, error);
          // Replaced with modal in App.vue
          // alert(i18nGlobal.t('appErrors.backendCommunicationError') + "\nerror code: 42");
          this.modalErrorOpen = true;
          break;
        }
      }
    },
  }
});
