import { defineStore } from "pinia";
import { useMembersStore }     from "@/store/MembersStore";
import { useInputOptionStore } from "@/store/InputOptionStore";
import de from "../locales/de.js";
import fr from "../locales/fr.js";
import it from "../locales/it.js";

export const useEmailStore = defineStore("EmailStore", {
  state: () => {
    return {
      forward: "Weiter",
      routeBack: "/membership-order",
      routeForward: "/membership-offer",
      placeholder: "@hin.ch",
      praxisSoftwareSelect: "",
      hasOnlyTeamID: false,
    };
  },
  getters: {
    praxisSoftwareOptions: (state) => {
      const praxisSoftwareOptions = {de: [], fr: [], it: []};
      for (const key in de.emailForm.praxisSoftwareOptions) {
        for (const lang of ['de', 'fr', 'it']) {
          praxisSoftwareOptions[lang].push({
            key,
            de: de.emailForm.praxisSoftwareOptions[key],
            fr: fr.emailForm.praxisSoftwareOptions[key],
            it: it.emailForm.praxisSoftwareOptions[key],
          })
        }
      }
      return praxisSoftwareOptions;
    },
  },
  actions: {
    enableForms(teamForms, personalForms, mpaForms) {
      return (
        teamForms.length > 0 || personalForms.length > 0 || mpaForms.length > 0
      );
    },
    checkEidAmount(teamForms, personalForms, mpaForms) {
      return (
        (teamForms.length === 1 && personalForms.length !== 1 && mpaForms.length !== 1) ||
        (personalForms.length === 1 && teamForms.length !== 1 && mpaForms.length !== 1) ||
        (mpaForms.length === 1 && teamForms.length !== 1 && personalForms.length !== 1)
      );
    },
    changePlaceholder(associationSelection, teamForms, personalForms, mpaForms) {
      if (associationSelection && !this.checkEidAmount(teamForms, personalForms, mpaForms)) {
        // If association has domain, use it.
        const inputOptionStore = useInputOptionStore();
        // The language does not matter.
        const assocFull = inputOptionStore?.inputOptions?.associations?.de?.filter(
          item => item.key === associationSelection)[0];
        if (assocFull?.domain) {
          this.placeholder = "@"+assocFull.domain;
        }

        // If user selected association listed for praxis software,
        // overrule the domain if they selected a praxis software.
        const membersStore = useMembersStore();
        const overruleDomain = membersStore.hasPraxisSoftware;
        if (overruleDomain && this.praxisSoftwareSelect && this.praxisSoftwareSelect !== "no-software") {
          this.placeholder = "@"+overruleDomain;
        }
      } else {
        this.placeholder = "@hin.ch";
      }
      return this.placeholder;
    },
  },
  persist: {
    storage: sessionStorage,
  }
});
