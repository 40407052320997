<template>
  <div>
    <PersonalForm
      v-for="(form, index) in forms"
      :key="form"
      :ref="(el) => cardRefs[index] = el"
      :firstName="forms[index].firstName"
      :lastName="forms[index].lastName"
      :email="forms[index].email"
      :formString="index === 0 && formName === 'personalForms'
          ? $t('identityForm.firstPersonalForm')
          : formString
      "
      :trashCanText="trashCanText"
      :index="index"
      :formName="formName"
      @updateValue="handleChange(form, $event)"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import PersonalForm from "./PersonalForm.vue";
const props = defineProps({
  formString: String,
  trashCanText: String,
  formName: String,
  forms: { type: Array, required: true },
});

const cardRefs = ref([]);

function focusCard(index) {
  cardRefs.value[index].focusFirstInput();
}

defineExpose({
  focusCard
});

function handleChange(form, event) {
  if(event.srcElement.name.includes('firstName')) {
    form.hinEmail.value = event.target.value + "." + form.lastName.value;
  } else {
    form.hinEmail.value = form.firstName.value + "." + event.target.value;
  }
}
</script>
