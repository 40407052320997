<template>
  <!-- If we got our mailto back, we show it, plus a link.
       otherwise the transactionNumber is unknown to the backend,
       then it is useless to show a link with our transaction number.
       Also, e-mails that we might still have in the frontend look irrelevant.
  -->
  <div v-if="videoIdentStore.identFailure.mailto">
    <p>
      {{ $t("identSuccess.error.restart") }}
      <a class="text-primary" :href="'/video-identification?token='+videoIdentStore.token"> <!-- NOSONAR -->
        {{ $t("identSuccess.error.restartIdent") }}
      </a>
    </p>
    <p>{{ $t("identSuccess.error.text", {email: videoIdentStore.identFailure.mailto}) }}</p>
  </div>
  <div v-else>
    <i18n-t keypath="identSuccess.error.noMatch" tag="p">
      <template v-slot:supportLink>
        <a class="text-primary" :href="$t('identSuccess.error.supportLink')">{{ $t("identSuccess.error.support") }}</a>
      </template>
    </i18n-t>
  </div>
  <div>
    <div class="h6">
      {{ $t("identSuccess.button.close") }}
    </div>
  </div>
</template>
<script setup>
import { useAppStore } from "@/store/AppStore";
const appStore = useAppStore();
appStore.header = "identSuccess.error.title";

import { useVideoIdentStore } from "@/store/VideoIdentStore";
const videoIdentStore = useVideoIdentStore();
videoIdentStore.handleIdentFailure();
</script>
