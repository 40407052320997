export function useAgwManagerService() {
  async function fetchJson(url, options) {
    let json = {};
    try {
      const response = await fetch(import.meta.env.VITE_AGW_MANAGER_URL+url, options);
      json = await response.json();
      if (! json.errors && !json.result) {
        console.log("Malformed response: ", json);
        return {errors: [{message: "Malformed response"}]};
      }
    }
    catch (err) {
      console.log("Error making backend call: ", err);
      return {errors: [{message: "Failed to make backend call"}]};
    }
    return json;
  }

  return {fetchJson};
}
