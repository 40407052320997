<template>
  <div class="col-11">
    <div class="row">
        <h3 class="text-md-left d--mvb-1">
          {{ $t(header, appStore.headerNum) }}
        </h3>
      </div>
    </div>

    <div id="basicForm" class="shadow-sm">
      <slot name="mask"></slot>
    </div>
</template>

<script setup>
import { useAppStore } from "../store/AppStore";

const appStore = useAppStore();

const props = defineProps({
  header: String,
  headerNum: 1,
  showCaching: {
    type: Boolean,
    default: true,
  },
});
</script>

<style scoped>
#basicForm {
  padding: 20px;
}

#formContainer {
  padding-top: 20px;
}

#cacheLink {
  padding-top: 10px;
}
</style>
