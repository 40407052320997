import { defineStore } from "pinia";

import { useAgwManagerService } from "@/services/AgwManagerService";
import { useAppStore } from "@/store/AppStore";
const agwManagerService = useAgwManagerService();

export const useVideoIdentStore = defineStore("VideoIdentStore", {
  // Store the token to local store, so user is not
  // immediately thrown out if they refresh, come back later etc.
  state: () => {
    return {
      contractSet: "",
      inputs: {
        forPers: {
          professions: [""],
          profTitles: [],
        },
        forTeam: {},
      },
      errors: {
        forPers: {
          professions: [""],
          profTitles: [""],
        },
        forTeam: {},
      },
      info: {
        forPers: {
          professions: [""],
          profTitles: [""],
        },
        forTeam: {},
      },
      idnowIdentUrl: "",
      idnowError: "",
      token: "",
      firstName: "",
      lastName: "",
      email: "",
      hinEmail: "",
      // association: "",
      orgName: "",
      professionsWithTitle: {
        309343006: 1, // medical doctor
        46255001:  1, // pharmacist
        106289002: 1, // dentist
        59944000:  1, // psychologist
        3842006:   1, // chiro
      },
      type: "", // personalId, personalMpaId, teamId
      flatErrors: [],
      savingInProgress: false,
      savingErrors: [],
      identFailure: {},
      identSuccess: {
        loading: true,
        errors: [],
        furtherEids: [],
        numIEids: 0,
        buttonDisabled: false,
        emailSendProgress: "not started",
      }
    };
  },
  actions: {
    async fetchIdnowIdentUrl() {
      // We support two cases.
      // - Token in the store, that is the
      //   case if user comes from productfinder wizard, step 6 (persons),
      //   or step 5 (teams).
      // - Token in query parameters. That happens for
      //   Video Ident by invitation or after an error (ident-success / -failure)
      if (!this.token) {
        const urlParams = new URLSearchParams(window.location.search);
        this.token = urlParams.get('token');
      }
      const responseData = await agwManagerService.fetchJson(
        "/video-ident/idnow?token="+this.token,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (responseData.errors) {
        this.idnowError = responseData.errors[0];
      }
      else {
        this.idnowIdentUrl = responseData.result.idnowIdentUrl;
      }
    },
    async handleIdentFailure() {
      const responseData = await agwManagerService.fetchJson(
        "/video-ident/ident-failure"+window.location.search,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json'
          },
        }
      );
      if (responseData.result?.mailto) {
        this.identFailure.mailto = responseData.result.mailto;
        this.token = responseData.result.token;
      }
    },
    async handleIdentSuccess() {
      const responseData = await agwManagerService.fetchJson(
        "/video-ident/ident-success"+window.location.search,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json'
          },
        }
      );
      const appStore = useAppStore();
      appStore.currentLanguage = responseData?.result?.language || 'DE';
      this.identSuccess.loading = false;
      if (responseData.errors) {
        this.identSuccess.errors = responseData.errors;
        appStore.header = "identSuccess.error.title";
      }
      else if (responseData.result?.furtherEids){
        this.identSuccess.mailto = responseData.result.mailto;
        this.identSuccess.furtherEids = responseData.result.furtherEids;
        this.identSuccess.numEids = this.identSuccess.furtherEids.length
        if (this.identSuccess.numEids > 0) {
          appStore.header = "identSuccess.withEids.title";
          appStore.headerNum = this.identSuccess.numEids;
        }
        else {
          appStore.header = "identSuccess.withoutEids.title";
        }
      }
      else {
        appStore.header = "identSuccess.error.title";
      }
    },
    async handleSubmit() {
      this.savingInProgress = true;
      this.flatErrors.splice(0, this.flatErrors.length);
      const type = this.firstName ? "person" : "team";
      // Make deep copy. Not very efficient. Should work here because only primitives.
      const payload = JSON.parse(JSON.stringify(
        this.firstName ? this.inputs.forPers : this.inputs.forTeam));
      payload.token = this.token;
      // profTitles can be `null` (so no field is shown). However, we can only
      // send empty strings.
      if (payload.profTitles) {
        payload.profTitles = payload.profTitles?.map(item => item ?? "");
      }
      // must not send empty professions
      if (payload.professions) {
        payload.professions = payload.professions.filter(item => item !== null && item !== "");
      }
      const responseData = await agwManagerService.fetchJson(
        "/video-ident/information-"+type,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload),
        }
      );
      if (responseData.errors) {
        _handleErrors(responseData, this.errors, this.flatErrors,
          this.savingErrors, this.firstName);
      }
      else {
        this.savingErrors.splice(0, this.savingErrors.length);
      }
      this.savingInProgress = false;
      return responseData;
    },
    async sendEmails() {
      // Only send once.
      // If it fails, rather go through support than spam people.
      // Not explicitly specified. Might change in the future.
      this.identSuccess.buttonDisabled = true;

      const responseData = await agwManagerService.fetchJson(
        "/video-ident/ident-success"+window.location.search,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
        }
      );
      if (responseData.result) {
        this.identSuccess.emailSendProgress = "success";
      }
      else {
        this.identSuccess.emailSendProgress = "failed";
      }
    },
  },
  persist: {
    storage: sessionStorage,
  }
});

function _handleErrors(responseData, errors, flatErrors, savingErrors, firstName) {
  flatErrors.push(responseData.errors);
  const camelCaseType = firstName ? "forPers" : "forTeam";
  for (const error of responseData.errors) {
    if (!error.path) {
      savingErrors.push(error);
      console.log(error);
      break;
    }

    let currentObject = errors[camelCaseType];

    // If user does not provide professions (which is an array with a
    // mandatory minimum length of 1), we get "/body/professions/: "Not enough elements: 0/1."
    // Set a "missing input" on the first element in that case makes sense
    // because that is what user has to do.
    if (error.path === "/body/professions" && error.message === "Not enough items: 0/1.") {
      currentObject.professions[0] = "Missing property.";
      continue;
    }

    const pathParts = error.path.split('/').slice(2); // Remove empty string at the beginning
    currentObject = _findCurrentObject(currentObject, pathParts);

    const elem = pathParts[pathParts.length -1];
    let itemMatch;
    if (elem && (itemMatch = elem.match(/^item(\d+)$/))) {
      currentObject[itemMatch[1]] = error.message;
    }
    else {
      currentObject[elem] = error.message;
    }
  }
}

function _findCurrentObject(currentObject, pathParts) {
  for (let i = 0; i < pathParts.length - 1; i++) {
    const key = pathParts[i];
    if (!currentObject[key]) {
      currentObject[key] = {};
    }
    currentObject = currentObject[key];
  }
  return currentObject;
}
