<script setup>

const props = defineProps({
  header: "",
  bodyComponent: "",
  bodyComponentProps: "",
});

const emit = defineEmits(['close']);

</script>

<template>
  <!-- HTML copied from HIN design system -->
  <dialog class="modal d--modal" aria-labelledby="modalHeader" aria-describedby="modalBody">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <button type="button" class="close mr-1" data-dismiss="modal" aria-label="Close"
            @click="emit('close')"
        >
          <span aria-hidden="true"><i class="mdi mdi-close mdi-18px"></i></span>
        </button>
        <div class="modal-header">
          <h4 class="modal-title">{{ header }}</h4>
        </div>
        <div class="modal-body">
          <component :is="bodyComponent" v-bind="bodyComponentProps"/>
        </div>
        <div class="modal-footer">
          <button id="modalBtnSubmit" type="button" class="btn btn-primary"
            @click="emit('close')"
          >
            {{ $t("membersForm.modalDismissButton") }}
          </button>
        </div>
      </div>
    </div>
  </dialog>
</template>

<!-- I had to patch two styles, otherwise the background was white
     and the modal would not show at all. -->
<style>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
}

</style>
