<template>
  <div>
    <div>
      <card-body class="d--mvt-7 padding-zero">
        <div style="display: flex">
          <div
            style="flex: 1"
            class="col-lg-10 col-md-10 col-sm-1 col-1 max-width"
          >
            <form
              id="hreg--form"
              name="hreg--form"
              class="hreg--form needs-validation"
              autocomplete="off"
              method="post"
              action="" novalidate
              @submit.prevent="handleSubmit"
            >
              <InputCheckbox
                v-model="inputs.cbPersForm"
                name="cbPersForm"
                :label="$t('contractDetail.noBelongToOrg')"
              />

              <FormError
                v-if="errors.err"
                :message="$t('inputs.errorMessage')"
              />

              <ForPersonal
                v-show="inputs.cbPersForm"
                :errors="errors.forPersonal"
                :info="info.forPersonal"
                :inputs="inputs.forPersonal"
                :inputOptions
                :hasPersEids="identityStore.hasPersEids"
                :language="appStore.currentLanguage.toLowerCase()"
                :validators="validators(errors.forPersonal, unsetError)"
              />

              <ForOrganization
                v-show="!inputs.cbPersForm"
                :errors="errors.forOrganization"
                :info="info.forOrganization"
                :inputs="inputs.forOrganization"
                :inputOptions
                :hasPersEids="identityStore.hasPersEids"
                :language="appStore.currentLanguage.toLowerCase()"
                :validators="validators(errors.forOrganization, unsetError)"
              />

              <FormTitle
                :title ="$t('contractDetail.inputs.covercardTitle')"
              />
              <InputCheckbox
                v-model="inputs.covercardService.covercardOrder"
                name="covercardServicePers"
                >
                <template v-slot:label>
                  <i18n-t keypath="contractDetail.inputs.orderCovercardService" tag="p">
                    <template v-slot:covercardService>
                      <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('contractDetail.inputs.covercardServiceLink')">{{ $t("contractDetail.inputs.covercardService") }}</a>
                    </template>
                  </i18n-t>
                </template>
              </InputCheckbox>
              <CovercardService
                v-show="inputs.covercardService.covercardOrder"
                :emailStorePraxisSoftwareSelect="emailStore.praxisSoftwareSelect"
                :errors="errors.covercardService"
                :inputs="inputs.covercardService"
                :inputOptions
                :language="appStore.currentLanguage.toLowerCase()"
                :validators="validators(errors, unsetError)"
              />

              <FormTitle
                :title ="$t('form.feedbackMessageTitle')"
              />
              <InputTextarea
                name="inputMessage"
                :label="$t('form.feedbackMessageLabel')"
                v-model="inputs.inputMessage"
              />

              <FormTitle
                :title ="$t('form.contractTerms')"
              />
              <AckLegalTermsPersonal
                v-show="inputs.cbPersForm"
                :errors="errors.ackLegalTerms"
                :inputs="inputs.ackLegalTerms"
                :eIds="identityStore"
                :validators="validators(errors.ackLegalTerms, unsetError)"
              />

              <AckLegalTermsOrganization
                v-show="!inputs.cbPersForm"
                :errors="errors.ackLegalTerms"
                :inputs="inputs.ackLegalTerms"
                :eIds="identityStore"
                :validators="validators(errors.ackLegalTerms, unsetError)"
              />

              <FormSpacer />

              <div class="hreg--buttonbar">
                <router-link tabindex="-1" to="/membership-offer">
                  <button
                    type="button"
                    class="btn btn-link"
                  >
                    {{ $t("appComponents.btnBack") }}
                  </button>
                </router-link>
                <Popper placement="top" arrow>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-loading-text="Processing"
                    :style="disableSubmit ? 'opacity:.45' : '' "
                  >
                    {{ $t('contractDetail.button.forward') }}
                  </button>

                  <template #content>
                    <div v-if="contractDetailStore.savingInProgress">
                      <h6>{{ $t("contractDetail.button.savingInProgressTitle") }}</h6>
                      <p>{{ $t("contractDetail.button.savingInProgressText") }}</p>
                    </div>
                    <div v-else-if="Object.keys(errors.eIds).length > 0">
                      <h6>{{ $t("contractDetail.button.eIdErrorsTitle") }}</h6>
                      <p>{{ $t("contractDetail.button.eIdErrorsText") }}</p>
                    </div>
                    <div v-else-if="Object.keys(contractDetailStore.flatContractDetailErrors).length > 0">
                      <h6>{{ $t("contractDetail.button.formErrorsTitle") }}</h6>
                      <p>{{ $t("contractDetail.button.formErrorsText") }}</p>
                    </div>
                    <div v-else-if="Object.keys(contractDetailStore.savingErrors).length > 0">
                      <h6>{{ $t("contractDetail.button.savingErrorsTitle") }}</h6>
                      <i18n-t keypath="contractDetail.button.savingErrorsText" tag="p">
                        <template v-slot:supportLink>
                          <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('identSuccess.error.supportLink')">{{ $t("identSuccess.error.support") }}</a>
                        </template>
                      </i18n-t>
                      <p>
                      {{ $t("form.errors.codeLabel") }}:
                      {{contractDetailStore.savingErrors[0].code}}
                      </p>
                    </div>
                    <div v-else>
                      <h6>{{ $t("contractDetail.button.savingTitle") }}</h6>
                      <p>{{ $t("contractDetail.button.savingText") }}</p>
                    </div>
                  </template>
                </Popper>
              </div>

              <FormSpacer />

            </form>
          </div>
        </div>
      </card-body>
    </div>
  </div>
</template>

<script setup>
import CardBody                  from "@/components/CardBody.vue";
import CovercardService          from "@/components/contract-detail/CovercardService.vue";
import FormError                 from "@/components/form/FormError.vue";
import FormSpacer                from "@/components/form/FormSpacer.vue";
import FormTitle                 from "@/components/form/FormTitle.vue";
import AckLegalTermsPersonal     from "@/components/contract-detail/AckLegalTermsPersonal.vue";
import AckLegalTermsOrganization from "@/components/contract-detail/AckLegalTermsOrganization.vue";
import ForPersonal               from "@/components/contract-detail/ForPersonal.vue";
import ForOrganization           from "@/components/contract-detail/ForOrganization.vue";
import InputCheckbox             from "@/components/form/InputCheckbox.vue";
import InputTextarea             from "@/components/form/InputTextarea.vue";

import {validators} from "@/utils/validators.js";

import { computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import { useContractDetailStore } from "@/store/ContractDetailStore";
const contractDetailStore = useContractDetailStore();
const inputs = contractDetailStore.inputs;
const info = contractDetailStore.info;

import { useMembersStore } from "@/store/MembersStore";
const membersStore = useMembersStore();

import { useIdentityStore } from "@/store/IdentityStore";
const identityStore = useIdentityStore();

import { useAppStore } from "@/store/AppStore";
const appStore = useAppStore();
appStore.activeStep = appStore.screenNumbers.contractDetail;

import { useVideoIdentStore } from "@/store/VideoIdentStore";
const videoIdentStore = useVideoIdentStore();

import { useEmailStore } from "../store/EmailStore";
const emailStore = useEmailStore();

import { useOfferStore } from "@/store/OfferStore";
const offerStore = useOfferStore();

// We do not check eIDs here, because that is either
// an alert if they were somehow lost, or a popover
// after submit "please go back to pages 2/3".
const disableSubmit = computed(() => {
  const eIdsNum = identityStore.personalForms.length
                + identityStore.mpaForms.length
                + identityStore.teamForms.length;
  if (inputs.cbPersForm) {
    return _disableSubmitForPers(eIdsNum);
  }
  else {
    return _disableSubmitForOrg(eIdsNum);
  }
});

const _disableSubmitForPers = (eIdsNum) => {
  let differentInvoiceAddressOk = true;
  if (inputs.forPersonal.cbDifferentInvoiceAddress) {
    differentInvoiceAddressOk =
         inputs.forPersonal.invoiceAddress.inputLastname
      && inputs.forPersonal.invoiceAddress.inputStreet
      && inputs.forPersonal.invoiceAddress.inputStreetNumber
      && inputs.forPersonal.invoiceAddress.inputZipCode
      && inputs.forPersonal.invoiceAddress.inputCity
      && inputs.forPersonal.invoiceAddress.inputCountry
  }
  let acksOk = false;
  if ( eIdsNum <= 1) {
    acksOk =
         inputs.ackLegalTerms.cbAckLegalPersSingleMembership
      && inputs.ackLegalTerms.cbAckLegalPersSinglePersonallyResponsible
  }
  else {
    acksOk =
         inputs.ackLegalTerms.cbAckLegalPersCollectiveMembership
      && inputs.ackLegalTerms.cbAckLegalPersCollectivePersonallyResponsible
  }

  // Birth date only shown if "team eIDs only".
  let birthdateOk = true;
  if (! identityStore.hasPersEids) {
    birthdateOk = inputs.forPersonal.inputPersBirthdate;
  }

  return !(
       inputs.forPersonal.inputPersSalutation
    && inputs.forPersonal.inputPersFirstname
    && inputs.forPersonal.inputPersLastname
    && inputs.forPersonal.inputPersStreet
    && inputs.forPersonal.inputPersStreetNumber
    && inputs.forPersonal.inputPersZipCode
    && inputs.forPersonal.inputPersCity
    && inputs.forPersonal.inputPersCountry
    && inputs.forPersonal.inputPersLanguage
    && inputs.forPersonal.persEmailOk
    && inputs.forPersonal.inputPersPhone
    && inputs.forPersonal.inputPersPhoneCountry

    && birthdateOk

    && differentInvoiceAddressOk
    && acksOk
  );
};

const _disableSubmitForOrg = (eIdsNum) => {
  let differentInvoiceAddressOk = true;
  if (inputs.forOrganization.cbDifferentInvoiceAddress) {
    differentInvoiceAddressOk =
         inputs.forOrganization.invoiceAddress.inputLastname
      && inputs.forOrganization.invoiceAddress.inputStreet
      && inputs.forOrganization.invoiceAddress.inputStreetNumber
      && inputs.forOrganization.invoiceAddress.inputZipCode
      && inputs.forOrganization.invoiceAddress.inputCity
      && inputs.forOrganization.invoiceAddress.inputCountry

  }
  let acksOk = false;
  if ( eIdsNum <= 1) {
    acksOk =
         inputs.ackLegalTerms.cbAckLegalOrgSingleMembership
      && inputs.ackLegalTerms.cbAckLegalOrgSinglePersonallyResponsible
  }
  else {
    acksOk =
          inputs.ackLegalTerms.cbAckLegalOrgCollectiveMembership
  }

  // Birth date only shown if "team eIDs only".
  let birthdateOk = true;
  if (! identityStore.hasPersEids) {
    birthdateOk = inputs.forOrganization.inputBusinessContactBirthdate;
  }

  return !(
       inputs.forOrganization.inputOrgName
    && inputs.forOrganization.inputOrgStreet
    && inputs.forOrganization.inputOrgStreetNumber
    && inputs.forOrganization.inputOrgZipCode
    && inputs.forOrganization.inputOrgCity
    && inputs.forOrganization.inputOrgCountry
    && inputs.forOrganization.inputOrgType
    && inputs.forOrganization.orgEmailOk
    && inputs.forOrganization.inputOrgPhoneCountry
    && inputs.forOrganization.inputOrgPhone
    && inputs.forOrganization.inputOrgLanguage
    && inputs.forOrganization.inputBusinessContactSalutation
    && inputs.forOrganization.inputBusinessContactFirstname
    && inputs.forOrganization.inputBusinessContactLastname
    && inputs.forOrganization.businessContactEmailOk

    && birthdateOk

    && differentInvoiceAddressOk
    && acksOk
  );
};

const handleSubmit = async () => {
  const responseData = await contractDetailStore.handleSubmit();
  if (responseData.result) {
    videoIdentStore.token     = responseData.result.first.token;
    videoIdentStore.firstName = responseData.result.first.inputFirstname;
    videoIdentStore.lastName  = responseData.result.first.inputLastname;
    videoIdentStore.email     = responseData.result.first.inputEmail;
    videoIdentStore.hinEmail  = responseData.result.first.inputHinEmail;
    videoIdentStore.type      = responseData.result.first.type;

    // This is OK, because there is no team form.
    videoIdentStore.inputs.forPers.association = membersStore.associationSelection;
    videoIdentStore.contractSet = offerStore.offer?.meta?.offeredSet?.name;

    // Preset salutation & phone number
    // For organizations, take them from contact person,
    // for non-organizations, take from general info.
    videoIdentStore.inputs.forPers.salutation = inputs.cbPersForm
      ? inputs.forPersonal.inputPersSalutation
      : inputs.forOrganization.inputBusinessContactSalutation;

    videoIdentStore.inputs.forPers.phoneCountry = inputs.cbPersForm
      ? inputs.forPersonal.inputPersPhoneCountry
      : inputs.forOrganization.inputBusinessContactPhoneCountry;
    videoIdentStore.inputs.forPers.phone = inputs.cbPersForm
      ? inputs.forPersonal.inputPersPhone
      : inputs.forOrganization.inputBusinessContactPhone;

    videoIdentStore.teams = identityStore.teamForms;

    try {
      sessionStorage.removeItem("ContractDetailStore");
      sessionStorage.removeItem("MembersStore");
      sessionStorage.removeItem("IdentityStore");
      sessionStorage.removeItem("OfferStore");
    } catch (error) {
      console.error(error);
    }

    if (responseData.result.first.type === "teamId") {
      router.push("/video-identification-welcome");
    }
    else {
      router.push("/further-information");
    }
  }
};

// If they choose to have only team e-IDs, they directly go from
// this screen to the video identification. For that, they need a
// birth date. For that case, we collect it in this screen.
const errors = contractDetailStore.errors;
const unsetError = (errors, event) => {
  // The form is structured, "invoiceAddress" is a sub-section.
  // For example, we get "forOrganization.invoiceAddress.inputLastName",
  // (we have to prefix with "forXxx" because no two inputs must have
  // the same name.
  // so we unset the error in "errors.forOrganization.invoiceAddress.lastName".
  const parts = event.target.name.split(".");
  const name = parts.pop();
  if (parts.length && parts[1] === 'invoiceAddress') {
    errors[parts[1]][name] = "";
  }
  else if (parts.length && parts[0] === 'covercardService') {
    errors.covercardService[name] = "";
  }
  else {
    errors[name] = "";
  }
};

import { useInputOptionStore } from "@/store/InputOptionStore";
const inputOptionStore = useInputOptionStore();
const inputOptions = inputOptionStore.inputOptions;

// Set defaults
_setDefaultValues();
async function _setDefaultValues() {
  const personalEid = identityStore.personalForms[0] || identityStore.mpaForms[0] || {
    firstName: {error: "", value: ""},
    lastName: {error: "", value: ""},
    email: {error: "", value: ""},
  };

  inputs.forOrganization.inputBusinessContactFirstname    ||= personalEid.firstName.value;
  inputs.forOrganization.inputBusinessContactLastname     ||= personalEid.lastName.value;
  inputs.forOrganization.inputBusinessContactEmail        ||= personalEid.email.value;
  inputs.forOrganization.inputOrgCountry                  ||= "CH";
  inputs.forOrganization.inputOrgLanguage                 ||= appStore.currentLanguage;
  inputs.forOrganization.inputOrgPhoneCountry             ||= "CH";
  inputs.forOrganization.inputBusinessContactPhoneCountry ||= "CH";
  inputs.forOrganization.invoiceAddress.inputCountry      ||= "CH";

  inputs.forPersonal.inputPersFirstname          ||= personalEid.firstName.value;
  inputs.forPersonal.inputPersLastname           ||= personalEid.lastName.value;
  inputs.forPersonal.inputPersCountry            ||= "CH";
  inputs.forPersonal.inputPersEmail              ||= personalEid.email.value;
  inputs.forPersonal.inputPersLanguage           ||= appStore.currentLanguage;
  inputs.forPersonal.invoiceAddress.inputCountry ||= "CH";
  inputs.forPersonal.inputPersPhoneCountry       ||= "CH";

  // Initialize email "ok"s.
  // inputPersEmail should never be empty / undefined, but be careful.
  inputs.forPersonal.persEmailOk = inputs.forPersonal.inputPersEmail
    ? await validators(errors.forPersonal, unsetError).backend(
      {
        target: {
          name: "inputPersEmail",
          value: inputs.forPersonal.inputPersEmail
        }
      },
      "contractDetailPers")
    : false;

  inputs.forOrganization.orgEmailOk = inputs.forOrganization.inputOrgEmail
    ? await validators(errors.forOrganization, unsetError).backend(
      {
        target: {
          name: "inputOrgEmail",
          value: inputs.forOrganization.inputOrgEmail
        }
      },
      "contractDetailOrg")
    : false;
  inputs.forOrganization.businessContactEmailOk = inputs.forOrganization.inputBusinessContactEmail
    ? await validators(errors.forOrganization, unsetError).backend(
      {
        target: {
          name: "inputBusinessContactEmail",
          value: inputs.forOrganization.inputBusinessContactEmail
        }
      },
      "contractDetailOrg")
    : false;

  if (!inputs.covercardService.practiceSoftware) {
    if (emailStore.praxisSoftwareSelect && emailStore.praxisSoftwareSelect !== "no-software"){
      inputs.covercardService.practiceSoftware =
        emailStore.praxisSoftwareOptions[appStore.currentLanguage.toLowerCase()]?.filter(
            item => item.key === emailStore.praxisSoftwareSelect
        )?.[0][appStore.currentLanguage.toLowerCase()]
    }
    else {
      inputs.covercardService.practiceSoftware = undefined;
    }
  }
}

</script>

<style scoped>
.max-width {
  max-width: 500px;
}
.padding-zero {
  padding-left: 0px;
}
</style>
