<template>
  <button
    id="removeFormButton"
    class="d--text-orange-600 btn d--btn-repeater d--btn-repeater-remove d--btn-repeater-inactive"
    type="button"
  >
    <div class="center-content">
      <i id="trashCan" class="mdi mdi-trash-can mdi-24px"></i>
      <span class="d--text-orange-600">{{ $t('identityForm.trashCanText') }}</span>
    </div>
  </button>
</template>

<script setup></script>

<style scoped>
#removeFormButton:hover {
  color: #f29800;
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
