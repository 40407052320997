<template>
  <div class="form-group">
    <LabelForInput :name="'email'+formName+index" :label class="d--mvb-0"/>
    <div class="input-container row">
      <div class="input-container col remove-padding">

        <input
        type="text"
        :class="['form-control', 'email-input', error && 'error-border']"
        :id="'email'+formName+index"
        :name="'email'+formName+index"
        :minLength
        :maxLength
        :value="
        props.form.hinEmail.value.split('@')[0].toLowerCase()
        "
        :placeholder="placeholder"
        @input="handleChange($event)"
        @blur="handleBoth($event)"
        />
        <DeleteInputIcon v-if="error" @clearInput="props.form.hinEmail.value = ''" />
      </div>
      <span class="email-domain">{{ placeholder }}</span>
    </div>
    <ErrorSmall name="email" :error />
  </div>
</template>

<script setup>
import LabelForInput from "./form/LabelForInput.vue";
import ErrorSmall from "./form/ErrorSmall.vue";
import DeleteInputIcon from "./form/DeleteInputIcon.vue";
import { onMounted, watch } from "vue";
import { useIdentityStore } from "@/store/IdentityStore";

const identityStore = useIdentityStore();
const emits = defineEmits(["validate"]);

const props = defineProps({
  form: {},
  formName: "",
  index: "",
  label: String,
  placeholder: String,
  defaultValue: String,
  error: String,
  minLength: 6,
  maxLength: 320,
});

const handleBoth = (event) => {
  handleChange(event);
  emits('validate', event);
};


function updateDomain() {
  // Always update the domain (edge case where user comes here, goes back to
  // association selection and comes back, here.
  // They want to preserve the part of the email before the "@", which could
  // be user edited.
  props.form.hinEmail.value =
    props.form.hinEmail.value === ""
      ? identityStore.cleanDiacritics(props.defaultValue)
      : identityStore.cleanDiacritics(props.form.hinEmail.value
          .replace(/@.*/, `${props.placeholder}`));
  // If they manage to delete the "@", append the domain..
  if (!props.form.hinEmail.value.includes("@")) {
    props.form.hinEmail.value += props.placeholder;
  }
}
onMounted(() => updateDomain());

watch(() => props.placeholder, (n, o) => updateDomain());

const handleChange = (event) => {
  if (event.target.value.includes("@") &&
    props.form.hinEmail.value !== event.target.value) {
    props.form.hinEmail.value = event.target.value.split("@")[0];
  } else {
    props.form.hinEmail.value = event.target.value + props.placeholder;
  }
};
</script>

<style scoped>
.email-input {
  max-width: 100%;
  padding-right: 30px;
}

.email-domain {
  margin-left: 5px;
}
.remove-padding {
  padding-right: 0;
}
</style>

