export function setGlnError(gln, errors, glnKey) {
  if (! gln) {
    errors[glnKey] = '';
    return;
  }
  if (! /^\d+$/.test(gln)) {
    errors[glnKey] = ['form.glnErrors.onlyDigits'];
    return;
  }
  if (gln.length < 13) {
    errors[glnKey] = ['form.glnErrors.tooShort'];
    return;
  }
  if (gln.length > 13) {
    errors[glnKey] = ['form.glnErrors.tooLong'];
    return;
  }
  errors[glnKey] = null;
}
