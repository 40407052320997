<template>
  <div class="hreg--buttonbar">
    <router-link tabindex="-1" :to="routeBack">
      <button tabindex="0" v-if="disableBackBtn === false" type="button" class="btn btn-link" @click="checkForEmpty()">
        {{ $t("appComponents.btnBack") }}
      </button>
    </router-link>
    <router-link tabindex="-1" :to="routeForward">
      <button
        tabindex="0"
        type="submit"
        class="btn btn-primary"
        data-loading-text="Processing"
        :disabled="disableBtn"
        data-testid="routeNextBtn"
        @click="checkForEmpty()"
      >
        {{ props.forwardBtnText }}
      </button>
    </router-link>
  </div>
</template>

<script setup>
import { useIdentityStore } from '@/store/IdentityStore';

const identityStore = useIdentityStore();

const props = defineProps({
  routeBack: {
    type: String,
    required: true,
  },
  routeForward: {
    type: String,
    required: true,
  },
  forwardBtnText: {
    type: String,
  },
  disableBtn: {
    type: Boolean,
    required: false,
  },
  disableBackBtn: {
    type: Boolean,
    required: false,
  },
});

function checkForEmpty() {
  let teamForms = identityStore.teamForms;
  let personalForms = identityStore.personalForms;
  let mpaForms = identityStore.mpaForms;

  identityStore.deleteEmptyIds(teamForms);
  identityStore.deleteEmptyIds(mpaForms);
  identityStore.deleteEmptyIds(personalForms);
};
</script>
