<template>
  <button
    id="removeFormButton"
    class="d--text-orange-600 btn d--btn-repeater d--btn-repeater-remove d--btn-repeater-inactive"
    type="button"
    tabindex="0"
    @click="removeForm(index, formName)" @keydown.enter="removeForm(index, formName)"
  >
    <div class="row align-items-center">
      <div class="col">
        {{ trashCanText }}
      </div>
      <i id="trashCan" class="mdi mdi-trash-can mdi-24px"></i>
    </div>
  </button>
</template>

<script setup>
import { useIdentityStore } from "../store/IdentityStore";

const identityStore = useIdentityStore();

const props = defineProps({
  trashCanText: String,
  formString: String,
  index: Number,
  formName: String,
});

const removeForm = (index, formName) => {
  const payload = {
    index: props.index,
    formName: props.formName,
  };
  identityStore.removeForm(payload);
};
</script>
<style scoped>
#removeFormButton:hover {
  color: #F29800;
}
#removeFormButton:focus {
  color: #F29800;
}
</style>