<script setup>
import ErrorSmall from "@/components/form/ErrorSmall.vue";
import InfoSmall from "@/components/form/InfoSmall.vue";
import DeleteInputIcon from "@/components/form/DeleteInputIcon.vue";
defineProps({
  info: "",
  hint: "",
  label: "",
  name: "",
  required: "",
  error: "",
  minLength: 0,
  maxLength: 255,
});

const value = defineModel();
const emits = defineEmits(['validate']);

// Workaround to not pass the refdata URL
// into us as a parameter.
import { useAppStore } from "@/store/AppStore";
const appStore = useAppStore();
</script>

<template>
  <div class="form-group">
    <label :for="name">
      {{ label }}
      <span>
        {{ hint }}
      </span>
      <span
        class="d--info-popover"
        data-toggle="popover"
        data-placement="top"
        data-trigger="focus"
        data-popover-content="#popover-content-gln"
        data-original-title
        title
      >

      <Popper placement="top" arrow>
        <i class="mdi mdi-information-outline"></i>
        <template #content>
          <div>
            <h6>{{ $t('form.glnTitle') }}</h6>
            <p v-if="appStore.currentLanguage === 'FR'">
              {{ $t('form.glnText') }}
              <a class="text-primary" href="//www.refdata.ch/fr/" target="_blank" rel="noopener">
                {{ $t('form.glnUrlText') }}
              </a>
            </p>
            <p v-else>
              {{ $t('form.glnText') }}
              <a class="text-primary" href="//www.refdata.ch/de/" target="_blank" rel="noopener">
                {{ $t('form.glnUrlText') }}
              </a>
            </p>
          </div>
        </template>
      </Popper>
      </span>
    </label>
    <div>
      <div class="input-container">
        <input
          type="text"
          :class="['form-control','max-width', error && 'error-border']"
          :id="name"
          :minLength
          :maxLength
          :name
          :required
          @blur="$emit('validate', $event)"
          v-model="value"
        />
        <DeleteInputIcon v-if="error" @clearInput="value = ''" />
        </div>
      </div>
    <InfoSmall :info/>
    <ErrorSmall :name :error :label/>
  </div>
</template>

<style>
  :root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover: #ffffff;
    --popper-theme-text-color: #333333;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #eeeeee;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }

  .max-width {
  max-width: 500px;
}
</style>
