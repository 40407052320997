import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from '@/App.vue';
import router from '@/router';
import i18n from '@/i18n.js';
import Popper from "vue3-popper";
import packageInfo from '../package.json';

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.config.globalProperties.$version = packageInfo.version;

// Maybe remove this once app is stable in prod.
if (import.meta.env.PROD) {
  app.config.errorHandler = (err, instance, info) => {
    window.console.log("Error", err);
    window.console.log("instance", instance);
    window.console.log("info", info);
  }
}

app.component("Popper", Popper);
app.use(pinia);
app.use(router);
app.use(i18n);
app.mount('#app');

