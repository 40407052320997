import { defineStore } from "pinia";
import { useAgwManagerService } from "@/services/AgwManagerService";
import { useMembersStore }      from "@/store/MembersStore";
import { useIdentityStore }     from "@/store/IdentityStore";
import { useOfferStore }        from "@/store/OfferStore";

const agwManagerService = useAgwManagerService();

export const useContractDetailStore = defineStore('ContractDetailStore', {
  state: () => {
    return {
      inputs: {
        ackLegalTerms: {},
        cbPersForm: false,
        forPersonal: {
          invoiceAddress: {},
          persEmailOk: true,
        },
        forOrganization: {
          invoiceAddress: {},
          orgEmailOk: true,
          businessContactEmailOk: true,
        },
        covercardService: {},
      },
      errors: {
        ackLegalTerms: {},
        covercardService: {},
        forPersonal: {
          invoiceAddress: {}
        },
        forOrganization: {
          invoiceAddress: {}
        },
        eIds: {},
      },
      info: {
        ackLegalTerms: {},
        covercardService: {},
        forPersonal: {
          invoiceAddress: {}
        },
        forOrganization: {
          invoiceAddress: {}
        },
        eIds: {},
      },
      flatContractDetailErrors: [],
      savingInProgress: false,
      savingErrors: [],
    };
  },
  actions: {
    // The "big" submit of all collected data: contract, eIds.
    async handleSubmit() {
      const membersStore  = useMembersStore();
      const identityStore = useIdentityStore();
      const offerStore    = useOfferStore();
      this.savingInProgress = true;
      const payload = _buildPayload(this.inputs, membersStore, identityStore, offerStore);
      const type    = this.inputs.cbPersForm ? "for-personal" : "for-organization";
      const responseData = await agwManagerService.fetchJson(
        "/productfinder/create-contract-"+type,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload),
        }
      );
      this.flatContractDetailErrors.splice(0, this.flatContractDetailErrors.length);
      this.errors.eIds = {};
      if (responseData.errors) {
        _handleErrors(type, responseData, identityStore,
          this.errors, this.savingErrors, this.flatContractDetailErrors);
      }
      else {
        this.savingErrors.splice(0, this.savingErrors.length);
      }
      this.savingInProgress = false;
      return responseData;
    },
  },
  persist: {
    storage: sessionStorage,
  }
});

function _handleErrors(type, responseData, identityStore, errors, savingErrors, flatErrors) {
  // Errors have the form:
  // {message: "Missing property", path: "/body/contractDetail/forPersonal/foo"}
  // That is the structure in the OpenAPI definition of the endpoint in the
  // AGW manager.
  // Turn that in a structure similar to the form inputs:
  // errors: {forPersonal: {foo: "Missing property"}, ...}
  const camelCaseType = type.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
  // In the OpenAPI definition, there are two paths, the first is for 1 eID,
  // the second is for multiple eIDs. If there are errors, OpenAPI validation tries
  // both branches and reports errors accordingly.
  // If we send one eID: ignore errors of path 1: multiple eIDs.
  // If we send multiple eIDs: ignore errors of path 0: one eID.
  const ignoreValidationPath = (
      identityStore.personalForms.length
    + identityStore.teamForms.length
    + identityStore.mpaForms.length)
    > 1
    ? 0 : 1;
  for (const error of responseData.errors) {
    if (!error.path) {
      savingErrors.push(error);
      console.log(error);
      break;
    }

    if (error.message.match(new RegExp("^/oneOf/"+ignoreValidationPath))) {
      continue;
    }

    const pathParts = error.path.split('/').slice(2); // Remove empty string at the beginning
    const currentObject = _findCurrentError(errors, pathParts, camelCaseType);
    // If there is an error on the "contractDetail" level, that is
    // a programming error, likely.
    // Make sure we still produce an error structure the templating
    // can consume.
    // length 1 does not go into for loop above
    if (pathParts.length === 1 && pathParts[0] === 'contractDetail') {
      errors.contractDetail[camelCaseType].root = error.message.replace(/['.]/g, "");
    }
    else {
      const lastKey = pathParts[pathParts.length - 1];
      currentObject[lastKey] = error.message.replace(/['.]/g, "");
    }

    if (error.path.match(/contractDetail|covercardService|ackLegalTerms/)) {
      flatErrors.push(error);
    }
  }
}

function _buildPayload(thisInputs, membersStore, identityStore, offerStore) {
  // Make sure we only send acks from visible form
  const type = thisInputs.cbPersForm ? "Pers" : "Org";
  const num = (identityStore.personalForms.length
             + identityStore.mpaForms.length
             + identityStore.teamForms.length) > 1 ? "Collective" : "Single";
  const visibleAcks = Object.keys(thisInputs.ackLegalTerms)
    .filter(key => key.startsWith(`cbAckLegal${type}${num}`))
    .reduce((acc, key) => ({...acc, [key]: thisInputs.ackLegalTerms[key]}), {});
  const inputs = thisInputs.cbPersForm ? thisInputs.forPersonal : thisInputs.forOrganization;
  // If they do not select any association, MembersStore is not persisted.
  // If they then refresh the browser, associationSelection is undefined, and the browser
  // does not sent "inputAssociation", triggering an error.
  const associationSelection = membersStore?.associationSelection || "";
  const payload = {
    ackLegalTerms: visibleAcks,
    contractDetail: { ...inputs,
      inputAssociation: associationSelection,
      retired: offerStore.retiredCheckbox,
      message: thisInputs.inputMessage,
    },
    ...(thisInputs.covercardService?.covercardOrder
      ? {covercardService: { ...thisInputs.covercardService }}
      : []
    ),
    eIds: {
      personalIds: identityStore.personalForms.map(({email, firstName, lastName, hinEmail}) => ({
        inputEmail: email.value,
        inputFirstname: firstName.value,
        inputLastname: lastName.value,
        inputHinEmail: hinEmail.value || email.value,
      })),
      personalMpaIds: identityStore.mpaForms.map(({email, firstName, lastName, hinEmail}) => ({
        inputEmail: email.value,
        inputFirstname: firstName.value,
        inputLastname: lastName.value,
        inputHinEmail: hinEmail.value || email.value,
      })),
      teamIds: identityStore.teamForms.map(({teamName, hinEmail}) => ({
        inputTeamName: teamName.value,
        inputHinEmail: hinEmail.value,
      })),
    },
  };

  // Must only send "invoiceAddress" if user ticked checkbox
  // or get errors for those inputs regardless of what user supplied
  if (!payload.contractDetail.cbDifferentInvoiceAddress) {
    delete payload.contractDetail.invoiceAddress;
  }
  // Must never send checkbox
  delete payload.contractDetail.cbDifferentInvoiceAddress;

  // Must only send birth date if only team eIDs
  if (identityStore.hasPersEids) {
    delete payload.contractDetail.inputBusinessContactBirthdate;
    delete payload.contractDetail.inputPersBirthdate;
  }

  // Do not send empty strings
  for (const key of ['inputOrgAddressSuffix', 'inputOrgGln', 'inputOrgUid']) {
    if (key in payload.contractDetail &&
      ( !payload.contractDetail[key] || /^\s*$/.test(payload.contractDetail[key]) ))
    {
      delete payload.contractDetail[key];
    }
  }

  // Do not send Ok fields
  delete payload.contractDetail.orgEmailOk;
  delete payload.contractDetail.businessContactEmailOk;
  delete payload.contractDetail.persEmailOk;

  return payload;
}
function _findCurrentError(errors, pathParts, camelCaseType) {
  let currentObject = errors;

  for (let i = 0; i < pathParts.length - 1; i++) {
    const key = pathParts[i];
    if (i === 0 && key === 'contractDetail') {
      if (!currentObject[camelCaseType]) {
        currentObject[camelCaseType] = {};
      }
      currentObject = currentObject[camelCaseType];
    }
    else {
      if (!currentObject[key]) {
        currentObject[key] = {};
      }
      currentObject = currentObject[key];
    }
  }
  return currentObject;
}


